<template>
  <v-app class="main" v-resize="onResize">
    <navigation v-if="getDataLoaded && !isGuest" v-resize="onResize"> </navigation>
    <v-main>
      <v-alert v-if="!isOnline"> Application is Offline. Please check your Internet Connection. </v-alert>
      <router-view v-if="showContent && getDataLoaded"> </router-view>
      <loading v-else> </loading>
    </v-main>
    <!-- <dashFooter></dashFooter> -->
    <snackBar></snackBar>
    <announcement></announcement>
    <addNewKiosk></addNewKiosk>
    <incoming-call></incoming-call>
    <call-status></call-status>
    <select-avatar></select-avatar>
    <avatar-config></avatar-config>
    <update-avatar-confirmation></update-avatar-confirmation>
    <kiosk-action-confirmation></kiosk-action-confirmation>
    <add-to-call-viewer></add-to-call-viewer>
    <add-to-call-status></add-to-call-status>
    <SingleDatePicker></SingleDatePicker>
    <WeekDatePicker></WeekDatePicker>
    <FiscalYearPicker></FiscalYearPicker>
    <YearPicker></YearPicker>
    <!-- <addNewGroup></addNewGroup> -->
  </v-app>
</template>

<script>
import snackBar from "@/components/notifications/snackBar.vue";
import announcement from "@/components/notifications/announcement.vue";
import navigation from "@/components/navigation/navigation.vue";
import loading from "@/components/loading.vue";
import dashFooter from "@/components/footer.vue";
import addNewKiosk from "@/components/dialogBox/kiosks/addNewKiosk.vue";
import addNewGroup from "@/components/dialogBox/groups/addNewGroup.vue";
import IncomingCall from './components/dialogBox/telepresence/incomingCall.vue';
import { mapGetters } from "vuex";
import router from './router';
import CallStatus from './components/dialogBox/telepresence/callStatus.vue';
import AvatarConfig from './components/dialogBox/avatar/avatarConfig.vue';
import updateAvatarConfirmation from "./components/dialogBox/avatar/updateAvatarConfirmation.vue";
import SelectAvatar from './components/dialogBox/avatar/selectAvatarViewer.vue';
import kioskActionConfirmation from "./components/dialogBox/kiosks/kioskActionConfirmation.vue";
import addToCallViewer from "./components/dialogBox/telepresence/addToCallViewer.vue";
import addToCallStatus from "./components/dialogBox/telepresence/addToCallStatus.vue";
import SingleDatePicker from "./components/dialogBox/datePickers/singleDatePicker.vue";
import WeekDatePicker from "./components/dialogBox/datePickers/weekDatePicker.vue";
import FiscalYearPicker from "./components/dialogBox/datePickers/fiscalYearPicker.vue";
import YearPicker from "./components/dialogBox/datePickers/yearPicker.vue";
export default {
  name: "App",
  components: {
    snackBar,
    navigation,
    loading,
    dashFooter,
    announcement,
    addNewKiosk,
    addNewGroup,
    IncomingCall,
    CallStatus,
    AvatarConfig,
    updateAvatarConfirmation,
    SelectAvatar,
    kioskActionConfirmation,
    addToCallViewer,
    addToCallStatus,
    SingleDatePicker,
    WeekDatePicker,
    FiscalYearPicker,
    YearPicker
  },
  data() {
    return {
      showContent: false,
      loading: true,
      isOnline: null
    };
  },
  computed: {
    ...mapGetters(["userProfile", "appData", "getDataLoaded", "isGuest", "userRole"]),
  },
  beforeCreate() {
    this.$store.dispatch("getAppData").then(() => {
      if (this.appData.initiated) {
        this.$vuetify.theme.themes.light = this.appData.themes.light;
        if (router.currentRoute.meta.requiresGuest) {
          this.$store.commit('setDataLoaded', true)
          this.$store.commit('setIsGuest', true)
        } else {
          this.$store.commit('setIsGuest', false)
        }
      } else {
        this.$store.commit('setDataLoaded', true)
        this.$store.commit('setIsGuest', true)
      }
      this.showContent = true;
      this.loading = false
    });
  },
  watch: {
    '$vuetify.breakpoint.name'(newBreakpoint, oldBreakpoint) {
      this.$store.dispatch('appDimensions', newBreakpoint)
      //this.currentBreakpoint = newBreakpoint;
      // You can perform actions or apply logic based on the new breakpoint name here
    }
  },
  methods: {
    onResize() {
      this.$store.dispatch('appDimensions', this.$vuetify.breakpoint.name)
    },
  },
  mounted() {
    console.info = function () { };
    window["console"]["log"] = function () {};
    setInterval(() => {
      this.isOnline = navigator.onLine ? true : false
    }, 1000)

  },
};
</script>

<style>
.main {
  background-color: #f5f5f5 !important;
}

body::-webkit-scrollbar {
  display: none;
}

.v-card::-webkit-scrollbar {
  -webkit-appearance: none;
  color: #6b1530;
  width: 10px;
}

.v-card::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px #6b1530;
}

.v-data-table__wrapper::-webkit-scrollbar {
  -webkit-appearance: none;
  color: #6b1530;
  width: 10px;
}

.v-data-table__wrapper::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px #6b1530;
}

/* .v-virtual-scroll::-webkit-scrollbar { */
/* -webkit-appearance: none; */
/* color: #6b1530; */
/* width: 10px; */
/* } */
/* .v-virtual-scroll::-webkit-scrollbar-thumb { */
/* border-radius: 2px; */
/* background-color: rgba(0, 0, 0, .5); */
/* -webkit-box-shadow: 0 0 1px #6b1530; */
/* } */
</style>
